import { useState, useEffect } from "react"

export const useGetDataChartP = ( url, token, removed, isweeklysale, datachart ) => {

	const [data, setData] = useState(null);
	const [errord, setError] = useState(null);
	const [loadingd, setLoading] = useState(true);

	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;
		if (isSubscribed) {
			// const getDataChart = () => {
			// 	setLoading(true)

			// 	fetch(`${url}dashventas?tipo=1`, {
			// 		method: 'GET',
			// 		headers: {
			// 			'Accept': 'application/json',
			// 			'Content-Type': 'application/json',
			// 			'Authorization': 'Bearer ' + token
			// 		}
			// 	})
			// 		.then(res => res.json())
			// 		.then((res) => {
			// 			setLoading(false)
			// 			// console.log(res)


			// 			const array = []
			// 			const colors = [
			// 				{ 'name': 'Abierto', 'color': '#9c27b0' },
			// 				{ 'name': 'Pagado', 'color': '#2196f3' },
			// 				{ 'name': 'Entregado', 'color': '#4caf50' },
			// 				{ 'name': 'Cancelado', 'color': '#E74C3C' },
			// 			]
			// 			res.cantidad.seriesCantidad.map((v, i) => {
			// 				// console.log(v.name)
			// 				colors.map((va, i) => {
			// 					if (va.name === v.name) {
			// 						// console.log('se encontro color')
			// 						// console.log('color para agrgar-' + va.color)
			// 						array.push(va.color)
			// 					}

			// 					return va

			// 				})
			// 				return v
			// 			})

			// 			// console.log(array)

			// 			const data = {
			// 				seriesC: res.cantidad.seriesCantidad,
			// 				seriesV: res.cantidad.seriesMoneda,
			// 				options: {
			// 					chart: {
			// 						height: 350,
			// 						type: 'line',
			// 						zoom: {
			// 							enabled: false
			// 						},
			// 						locales: [{
			// 							"name": "es",
			// 							"options": {
			// 								"months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			// 								"shortMonths": ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
			// 								"days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
			// 								"shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
			// 								"toolbar": {
			// 									"exportToSVG": "Descargar SVG",
			// 									"exportToPNG": "Descargar PNG",
			// 									"exportToCSV": "Descargar CSV",
			// 									"menu": "Menu",
			// 									"selection": "Selection",
			// 									"selectionZoom": "Selection Zoom",
			// 									"zoomIn": "Zoom In",
			// 									"zoomOut": "Zoom Out",
			// 									"pan": "Panning",
			// 									"reset": "Reset Zoom"
			// 								}
			// 							}
			// 						}],
			// 						defaultLocale: "es",
			// 					},

			// 					colors: array,
			// 					dataLabels: {
			// 						enabled: false
			// 					},
			// 					stroke: {
			// 						curve: 'straight'
			// 					},

			// 					grid: {
			// 						row: {
			// 							colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
			// 							opacity: 0.5
			// 						},
			// 					},
			// 					xaxis: {
			// 						categories: res.cantidad.categories,
			// 					}
			// 				},
			// 			}

			// 			setData(data)



			// 		})
			// 		.catch(e => setError(true))
			// 		.finally(() => console.log('finalizo chart'));

			// }

			const getDataChart = () => {
				setLoading(true)
				console.log( isweeklysale );
				if( isweeklysale == 0 ){

					fetch(`${url}dashventas?tipo=1` + ( isweeklysale ? '&isweeklysale=1' : '' ), {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + token
						}
					})
						.then(res => res.json())
						.then((res) => {
							setLoading( false );
							const array = [];
							const colors = [
								{ 'name': 'Abierto', 'color': '#9c27b0' },
								{ 'name': 'Pagado', 'color': '#2196f3' },
								{ 'name': 'Entregado', 'color': '#4caf50' },
								{ 'name': 'Cancelado', 'color': '#E74C3C' },
							];

							const data = {
								seriesC: [ {
									name: 'Dinero',
									data: res.data.series,
								} ],
								total: res.data.total,
								// seriesV: res.data.seriesMoneda,
								options: {
									chart: {
										height: 350,
										type: 'bar',
									},
									plotOptions: {
										bar: {
											borderRadius: 10,
											dataLabels: {
												position: 'top', // top, center, bottom
											},

										}
									},
									dataLabels: {
										enabled: true,
										formatter: function( val ){
											return '$' + Number( val ).toLocaleString( 'en-US' );
										},
										offsetY: -20,
										style: {
											fontSize: '12px',
											colors: [ '#304758' ],
											fontFamily: 'Gill Sans, sans-serif',
										}
									},

									xaxis: {
										categories: res.data.xaxis_categories,
										position: 'top',
										axisBorder: {
											show: false
										},
										axisTicks: {
											show: false
										},
										crosshairs: {
											fill: {
												type: 'gradient',
												gradient: {
													colorFrom: '#D8E3F0',
													colorTo: '#BED1E6',
													stops: [0, 100],
													opacityFrom: 0.4,
													opacityTo: 0.5,
													fontFamily: 'Gill Sans, sans-serif',
												}
											}
										},
										tooltip: {
											enabled: true,
										}
									},
									colors: [ '#009EE0' ],
									yaxis: {
										axisBorder: {
											show: false
										},
										axisTicks: {
											show: false,
										},
										labels: {
											show: false,
											formatter: function( val ){
												return '$' + Number( val ).toLocaleString( 'en-US' );
											}
										}

									},
									title: {
										text: '¿Cuanto dinero se tiene en cada almacen?',
										floating: true,
										offsetY: 330,
										align: 'center',
										style: {
											color: '#444'
										}
									},
									// fill: {
									// 	type: 'gradient',
									// 	gradient: {
									// 		shade: 'light',
									// 		type: "horizontal",
									// 		shadeIntensity: 0.25,
									// 		gradientToColors: undefined,
									// 		inverseColors: true,
									// 		opacityFrom: 0.85,
									// 		opacityTo: 0.85,
									// 		stops: [50, 0, 100]
									// 	},
									// 	fontFamily: 'Gill Sans, sans-serif',
									// }
								},
							}
							setData(data)
						})
						.catch( e => setError( true ) )
						.finally( () => console.log( 'finalizo chart' ) );
				} else
					setLoading( false );
			}
			getDataChart( url );
		}

	}, [url, token, removed]);

	return { data, errord, loadingd };
}